<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-275-px">
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          style="
            background-image: url('https://wallpapers.com/images/hd/twitter-header-1920-x-1200-background-q644du6f1cdg8n1n.jpg');
          "
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <!-- <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg> -->
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <swiper :module="[Navigation]" :slides-per-view="1" :space-between="10"  class="mySwiper md:space-between-5 lg:space-between-0 relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64" >
            <!-- Slide 1 -->
            <swiper-slide >
              <div>
                <div class="px-6">
                  <div class="flex justify-center mt-20">
                    <div class="w-full  px-4 flex justify-center" >
                      <div >
                        <img
                          alt="..."
                          :src="team2"
                          class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="text-center mt-32">
                    <h3
                      class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
                    >
                      Bryantama Putra
                    </h3>
                    <div
                      class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
                    >
                      <i
                        class="fas mr-2 text-lg text-blueGray-400"
                      ></i>
                      Fullstack Engineer
                    </div>
                    <div class="mb-2 text-blueGray-600 mt-5">
                      <i
                        class="fas fa-briefcase mr-2 text-lg text-blueGray-400"
                      ></i>
                      Adira Finance - Fullstack Engineer
                    </div>
                    <div class="mb-2 text-blueGray-600">
                      <i
                        class="fas fa-university mr-2 text-lg text-blueGray-400"
                      ></i>
                      Gunadarma University - Informatic Engineer
                    </div>
                  </div>
                  <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                    <div class="flex flex-wrap justify-center">
                      <div class="w-full lg:w-9/12 px-4">
                        <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                          Hi i'm Bryantama Putra, {{dates}} years of comprehensive experience as a Fullstack Engineer 
                          <br>Expertise spans: Java Spring, Vue.js, Node.js, Python, including MySQL, PostgreSQL, SQL Server, Oracle.
                          <br>Developed and maintained customer-related applications with focus on performance, scalability, reliability.
                          <br>Hands-on experience working serverless architectures, implementing CI/CD pipelines.
                        </p>
                      </div>
                    </div>
                    <button
                      class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                      @click="linkedinBtn"
                    >
                      <i class="fab fa-linkedin"></i>
                    </button>
                    <button
                      class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                      @click="gitBtn"
                    >
                      <i class="fab fa-github"></i>
                    </button>
                    <button
                      class="bg-white text-indigo-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                      @click="mailBtn"
                    >
                      <i 
                      class="fas fa-envelope"></i>
                    </button>
                    <button
                      class="bg-white text-orange-500 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                      @click="instagramBtn"
                    >
                      <i class="fab fa-instagram-square"></i>
                    </button>
                  </div>
                </div>
              </div>
            </swiper-slide>
            <!-- <swiper-slide>
              <div
              >
                <div class="px-6">
                  <div class="flex flex-wrap justify-center">
                    <div class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center" >
                      <div class="relative">
                        <img
                          alt="..."
                          :src="team2"
                          class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                        />
                      </div>
                    </div>
                    <div
                      class="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center"
                    >
                      <div class="flex justify-center lg:pt-4">
                        <div class="px-3 mt-32 sm:mt-0">
                          <button
                            class="bg-emerald-500 text-white active:bg-emerald-600 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                            type="button"
                            >
                            <i class="fas fa-arrow-alt-circle-down"></i> Download CV
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="w-full lg:w-4/12 px-4 lg:order-1">
                      <div class="flex justify-center">
                        <div class="mr-4 p-3 text-center">
                          <span
                            class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                          >
                            22
                          </span>
                          <span class="text-sm text-blueGray-400">Friends</span>
                        </div>
                        <div class="mr-4 p-3 text-center">
                          <span
                            class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                          >
                            10
                          </span>
                          <span class="text-sm text-blueGray-400">Photos</span>
                        </div>
                        <div class="lg:mr-4 p-3 text-center">
                          <span
                            class="text-xl font-bold block uppercase tracking-wide text-blueGray-600"
                          >
                            89
                          </span>
                          <span class="text-sm text-blueGray-400">Comments</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center mt-12">
                    <h3
                      class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
                    >
                      Bryantama Putra
                    </h3>
                    <div
                      class="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase"
                    >
                      <i
                        class="fas mr-2 text-lg text-blueGray-400"
                      ></i>
                      Fullstack Engineer
                    </div>
                    <div class="mb-2 text-blueGray-600 mt-8">
                      <i
                        class="fas fa-briefcase mr-2 text-lg text-blueGray-400"
                      ></i>
                      Adira Finance - Fullstack Engineer
                    </div>
                    <div class="mb-2 text-blueGray-600">
                      <i
                        class="fas fa-university mr-2 text-lg text-blueGray-400"
                      ></i>
                      Gunadarma University - Informatic Engineer
                    </div>
                  </div>
                  <div class="mt-10 py-10 border-t border-blueGray-200 text-center">
                    <div class="flex flex-wrap justify-center">
                      <div class="w-full lg:w-9/12 px-4">
                        <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                          Hi i'm Bryantama Putra, {{dates}} years of comprehensive experience as a Fullstack Engineer 
                          <br>Expertise spans: Java Spring, Vue.js, Node.js, Python, including MySQL, PostgreSQL, SQL Server, Oracle.
                          <br>Developed and maintained customer-related applications with focus on performance, scalability, reliability.
                          <br>Hands-on experience working serverless architectures, implementing CI/CD pipelines.
                        </p>
                      </div>
                    </div>
                    <button
                      class="bg-white text-lightBlue-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                    >
                      <i class="fab fa-twitter"></i>
                    </button>
                    <button
                      class="bg-white text-lightBlue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                    >
                      <i class="fab fa-facebook-square"></i>
                    </button>
                    <button
                      class="bg-white text-pink-400 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                    >
                      <i class="fab fa-dribbble"></i>
                    </button>
                    <button
                      class="bg-white text-blueGray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                      type="button"
                    >
                      <i class="fab fa-github"></i>
                    </button>
                  </div>
                </div>
              </div>
            </swiper-slide> -->
          </swiper>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import team2 from "@/assets/img/profile_foto.png";
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';
import { Navigation } from 'swiper';

var dates = new Date().getFullYear() - 2020;
export default {
  data() {
    return {
      team2,
      dates
    };
  },
  components: {
    Navbar,
    Swiper,
    SwiperSlide,
    FooterComponent,
  },
  setup() {
    return {
      Navigation
    };
  },
  methods:{
    linkedinBtn() {
      window.open('https://www.linkedin.com/in/tamaputra23/', '_blank');
    },
    instagramBtn() {
      window.open('https://www.instagram.com/tamaputra23/', '_blank');
    },
    mailBtn() {
      const recipient = 'tamaputra23@gmail.com';
      window.location.href = `mailto:${recipient}`;
    },
    gitBtn() {
      window.open('https://github.com/tamaputra23', '_blank');
    }
  }
};
</script>
